<template>
  <div v-if="src" style="height: 100%; overflow: hidden" v-loading="loading">
    <iframe
      :src="src"
      allowfullscreen="true"
      scrolling="auto"
      frameborder="0"
      style="width: 100%; height: 100%"
      @load="onLoaded"
    ></iframe>
  </div>
</template>
<script>
import Vue from "vue";
import { MES_STANDARD_URL } from "@/config";
import { logOut } from "@/service/account";
import { postToCommonServer } from "@/service/common";
export default {
  data() {
    return {
      uri: MES_STANDARD_URL,
      src: null,
      loading: true,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.src = null;
    next();
  },
  async created() {
    const { data: res } = await postToCommonServer("/PathConfigurationAPI/GetByPathConfiguration", {
        orgid: this.$store.state.loginUser.user.comp_id + ""
      }
    );
    this.src = this.uri;

    if (res.Success) {
      try {
        this.src = res.Content[0].Url + "/";
      } catch (e) {}
    }

    if (this.$route.query.path && this.$route.query.path.length > 0) {
      this.src += this.$route.query.path;
    } else {
      this.src += "center";
    }
    if (this.src.indexOf("?") >= 0) {
      this.src +=
        "&token=" +
        this.$store.state.loginUser.token +
        "&refresh_token=" +
        this.$store.state.loginUser.refresh_token;
    } else {
      this.src +=
        "?token=" +
        this.$store.state.loginUser.token +
        "&refresh_token=" +
        this.$store.state.loginUser.refresh_token;
    }
  },
  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        console.log("event", event);
        let result = JSON.parse(event.data);
        if (result.type == "to_login") {
          logOut().then();
        }
        if (result.type == "refresh_token") {
          // 更新用户token
          this.$store.dispatch("updateLogedUser", {
            token: result.token,
            refresh_token: result.refresh_token,
          });
        }
      },
      false
    );
  },
  methods: {
    onLoaded() {
      this.loading = false;
    },
  },
};
</script>